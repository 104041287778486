import React from 'react';

import Layout from '../layout/Layout';
import {
  Section,
  Grid,
  H2WithBorder,
  FlexCol,
  LinkWhiteButton,
} from '../components';

import { useIntl } from '../hooks';
import { colors, space, fontWeights } from '../theme';
import styled from 'styled-components';
import { graphql } from 'gatsby';

const StyledSection = styled(Section)`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ThankYouPage: React.FC<{ location: any; data: any }> = ({
  location,
  data,
}) => {
  const intl = useIntl();
  const header = data.contentfulHeader;
  const footer = data.contentfulFooter;

  return (
    <Layout
      hideFooter
      hideHeader
      location={location}
      header={header}
      footer={footer}
      seoTitle={intl.formatMessage({ id: 'thankYou.seoTitle' })}
    >
      <StyledSection py={space.xl} backgroundColor={colors.secondary}>
        <Grid.Container fluid>
          <H2WithBorder
            fontWeight={fontWeights.semiBold}
            color={colors.white}
            textAlign="center"
            title={intl.formatMessage({ id: 'thankYou.title' })}
            textHtml=""
          />
          <FlexCol alignItems="center" mt={space.md}>
            <LinkWhiteButton
              title={intl.formatMessage({ id: 'thankYou.button.text' })}
              to={intl.formatMessage({ id: 'thankYou.button.link' })}
            >
              {intl.formatMessage({ id: 'thankYou.button.text' })}
            </LinkWhiteButton>
          </FlexCol>
        </Grid.Container>
      </StyledSection>
    </Layout>
  );
};

export const query = graphql`
  query ThankYou($locale: String) {
    contentfulHeader(node_locale: { eq: $locale }) {
      menuItems {
        menuTitle
        menuLink
        isButton
        menuItems {
          menuTitle
          menuLink
          isButton
        }
      }
    }
    contentfulFooter(node_locale: { eq: $locale }) {
      menuItems {
        menuTitle
        menuLink
      }
      legalMenuItems {
        menuTitle
        menuLink
      }
    }
  }
`;

export default ThankYouPage;
